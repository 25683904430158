@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /*  
    Dynamic community colors that might be changes from the JS code regarding the actual community colors
    LM : #5f5cff = rgba(95, 92, 255, 1); DM: #6B68F3 = rgba(107, 104, 243, 1) 
  */
  --primary-color-100: rgba(95, 92, 255, 1);
  --primary-color-80: rgba(95, 92, 255, 0.8);
  --primary-color-60: rgba(95, 92, 255, 0.6);
  --primary-color-40: rgba(95, 92, 255, 0.4);
  --primary-color-10: rgba(95, 92, 255, 0.1);
  --primary-color-hovered: rgba(120, 117, 255, 1); /* #7875ff; */
  --primary-color-pressed: rgba(70, 66, 255, 1); /* #4642ff;*/

  --primary-color-100-dark: rgba(107, 104, 243, 1);
  --primary-color-80-dark: rgba(95, 92, 255, 0.8);
  --primary-color-60-dark: rgba(95, 92, 255, 0.6);
  --primary-color-40-dark: rgba(95, 92, 255, 0.4);
  --primary-color-10-dark: rgba(95, 92, 255, 0.1);
  --primary-color-hovered-dark: rgba(130, 128, 245, 1); /* #7875ff; */
  --primary-color-pressed-dark: rgba(83, 80, 241, 1); /* #4642ff; */
}

@layer base {
  h1 {
    @apply text-h1;
  }
  h2 {
    @apply text-h2;
  }
}

@layer components {
  .button-base-interactive {
    @apply btn-el w-full rounded-[8px] px-[24px] text-body16SB;
  }
  .button-base {
    @apply w-full rounded-[8px] px-[24px] text-body16SB;
  }

  .square-icon-button-base {
    @apply btn-standard flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[5px] text-body16SB;
  }

  .secondary-button {
    @apply h-[46px] bg-background-primary text-primary-100 shadow-button-shadow;
  }

  .primary-button {
    @apply button-base-interactive h-[46px] bg-primary-100 text-background-primary shadow-button-shadow hover:bg-primary-hover
    disabled:cursor-not-allowed disabled:opacity-50
    dark:bg-primary-100-dark dark:hover:bg-primary-hover-dark;
  }

  .primary-button-dynamic {
    @apply animation-classic themed-primary-bg-color-dynamic themed-primary-bg-color-dynamic-hovered 
    pressed-el-outline-dynamic-primary
    h-[46px] text-background-primary shadow-button-shadow;
  }

  .themed-btn {
    @apply themed-primary-bg-color-dynamic themed-primary-bg-color-dynamic-hovered themed-primary-bg-color-dynamic-pressed;
  }

  .themed-btn-text {
    @apply themed-primary-text-color-dynamic themed-primary-text-color-dynamic-hovered themed-primary-text-color-dynamic-pressed;
  }

  .themed-primary-bg-color-dynamic {
    @apply bg-[var(--primary-color-100)] dark:bg-[var(--primary-color-100-dark)];
  }

  .themed-primary-text-color-dynamic {
    @apply text-[var(--primary-color-100)] dark:text-[var(--primary-color-100-dark)];
  }

  .themed-primary-text-color-dynamic-hovered:not([disabled]) {
    @apply hover:text-[var(--primary-color-hovered)] dark:hover:text-[var(--primary-color-hovered-dark)];
  }

  .themed-primary-text-color-dynamic-pressed:not([disabled]) {
    @apply active:!text-[var(--primary-color-pressed)] dark:active:!text-[var(--primary-color-pressed-dark)];
  }

  .themed-primary-bg-color-dynamic-hovered:not([disabled]) {
    @apply hover:bg-[var(--primary-color-hovered)] dark:hover:bg-[var(--primary-color-hovered-dark)];
  }
  .themed-primary-bg-color-dynamic-pressed:not([disabled]) {
    @apply active:!bg-[var(--primary-color-pressed)] dark:active:!bg-[var(--primary-color-pressed-dark)];
  }

  .rounded-success-button {
    @apply h-[38px] rounded-[24px] bg-common-color-0 text-sm14SB text-text-primary-dark dark:bg-common-color-0-dark;
  }
  .outline-icon-button {
    @apply flex h-[40px] w-[48px] items-center justify-center;
  }
  .outline-button {
    @apply h-[46px] border-[1px] border-element-subtle bg-transparent text-text-primary  hover:bg-hovered-selected active:bg-pressed dark:hover:bg-hovered-selected-dark dark:active:bg-pressed-dark;
  }

  .outline-rounded-button {
    @apply h-[38px] rounded-[24px] border-[1px] border-text-primary bg-transparent text-text-primary hover:bg-hovered-selected active:bg-pressed dark:border-text-primary-dark dark:text-text-primary-dark dark:hover:bg-hovered-selected-dark dark:active:bg-pressed-dark;
  }
}

.text-gradient {
  @apply bg-gradient-to-r from-text-gradient-start to-text-gradient-end dark:from-text-gradient-start-dark dark:to-text-gradient-end-dark;
}

.background-gradient {
  @apply bg-gradient-to-r 
    from-background-gradient-start 
    via-background-gradient-middle 
    to-background-gradient-end
    dark:from-background-gradient-start-dark
    dark:via-background-gradient-middle-dark
    dark:to-background-gradient-end-dark;
}

.h1-no-m {
  @apply m-0 text-h1;
}

.themed-svg {
  @apply fill-color-1 dark:fill-text-primary-dark;
}

.themed-text {
  @apply text-text-primary dark:text-text-primary-dark;
}
.themed-svg-fill {
  @apply fill-text-primary dark:fill-text-primary-dark;
}
.themed-text-success {
  @apply text-element-success dark:text-element-success-dark;
}
.themed-text-error {
  @apply text-element-error dark:text-element-error-dark;
}
.themed-text-inverted {
  @apply text-text-primary-dark dark:text-text-primary;
}
.themed-text-secondary {
  @apply text-text-secondary dark:text-text-secondary-dark;
}

.themed-layout {
  @apply bg-background-primary dark:bg-background-primary-dark;
}
.themed-layout-secondary {
  @apply bg-background-secondary dark:bg-background-secondary-dark;
}

.themed-layout-error {
  @apply bg-element-error dark:bg-element-error-dark;
}

/* default hover effect for any interactive element */
.hover-el:not([disabled]) {
  @apply filter hover:brightness-125 !important;
}
.hover-el-opacity:not([disabled]) {
  @apply hover:opacity-70;
}
.pressed-el-opacity:not([disabled]) {
  @apply hover:opacity-50;
}

.pressed-el:not([disabled]) {
  @apply filter active:brightness-75 !important;
}

.pressed-el-outline-primary:not([disabled]) {
  @apply outline-none transition-all duration-200 active:outline active:outline-[2px] active:outline-primary-100 active:[outline-offset:3px];
}

.pressed-el-outline-dynamic-primary:not([disabled]) {
  @apply outline-none transition-all duration-200 active:outline active:outline-[2px] active:outline-[var(--primary-color-100)] active:[outline-offset:3px];
}

/* used for the general purpose as the button base that has default states as pressed/hovered. For now pressed is disabled till we have some general concern with the designer */
.btn-el {
  @apply hover-el pressed-el-outline-primary transition duration-200 ease-in-out;
  /* @apply hover-el; */
}

/* only for the elements that uses standard design styles for hover/pressed*/
.btn-standard:not([disabled]) {
  @apply animation-classic hover:bg-hovered-selected active:bg-pressed dark:hover:bg-hovered-selected-dark dark:active:bg-pressed-dark;
}

.btn-standard-non-hover:not([disabled]) {
  @apply animation-classic hover:bg-transparent active:bg-transparent dark:hover:bg-transparent dark:active:bg-transparent;
}

.animation-classic {
  @apply transition-all duration-200 ease-in-out;
}

.shrink {
  @apply transition-transform duration-100 ease-in-out active:scale-95;
}

.text-action {
  @apply hover-el cursor-pointer  active:opacity-75;
}

/* --- Color picker HUE --- */
.fnd-color-picker .hue-horizontal {
  padding: 0 7px 0 0 !important;
}

.fnd-color-picker .hue-horizontal > div {
  position: relative !important;
  height: 8px;
  width: 8px;
}

.action-rounded-button.active .action-rounded-button-icon {
  @apply flip-vertically;
}

.flip-vertically {
  transform: rotateX(180deg);
}

/* community card caption animation */

/* .community-card-caption-el {
  transition: all 0.22s ease-in-out;
}

.community-card-caption-bottom {
  transition: all 0.17s ease-in-out;
  opacity: 0;
}

.community-card-body:hover .community-card-caption-el {
  max-height: 310px;
}

.community-card-body:hover .community-card-caption-bottom {
  transition: all 0.22s ease-in-out 0.22s;
  opacity: 1;
} */

/* !community card caption animation */

/* TODO:Branding */
.popup-content {
  margin: auto;
  @apply bg-[#181818] dark:bg-[#181818];
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: auto !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

/* --- Swiper attachments--- */
.swiper {
  width: 100%;
  height: 100%;
}

.attachments-previews-slider .swiper-slide {
  width: 66px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.swiper-button-disabled:not(.control-arrow) {
  display: none;
}

.attachment-views .swiper-slide,
.attachments .swiper-slide {
  height: 100%;
  width: auto;
}

.attachment-views .swiper-nav-btn,
.attachments .swiper-nav-btn {
  display: none !important;
}

.attachment-views:hover .swiper-nav-btn:not(.swiper-button-disabled) {
  display: flex !important;
}

/* --- !Swiper --- */

/* --- Rich Editor --- */
.editor-scroller {
  width: 100%;
  height: 100%;
}
.editor {
  width: 100%;
  height: 100%;
  position: relative;
}

.ContentEditable__root {
  border: 0;
  font-size: 15px;
  display: block;
  position: relative;
  outline: 0;
  padding: 8px 28px 40px;
  min-height: 300px;
}

.readOnlyEditor {
  cursor: default;
  user-select: none;
  pointer-events: none;
  outline: none;
}

.Placeholder {
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px 28px 40px;
  font-size: 15px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

.divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: 36px;
  position: sticky;
  top: 0;
  z-index: 2;
}

button.toolbar-item.spaced {
  margin-right: 2px;
}

button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}

button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

button.toolbar-item:disabled .icon,
button.toolbar-item:disabled .text,
button.toolbar-item:disabled i.format,
button.toolbar-item:disabled .chevron-down {
  opacity: 0.2;
}

i.undo {
  background-image: url(/images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(/images/icons/arrow-clockwise.svg);
}

.dropdown {
  z-index: 100;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

button.item.dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

button.item.dropdown-item-active i {
  opacity: 1;
}

.icon.paragraph {
  background-image: url(/images/icons/text-paragraph.svg);
}

button.item i {
  opacity: 0.6;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}
button.toolbar-item.spaced {
  margin-right: 2px;
}

.icon.h1 {
  background-image: url(/images/icons/type-h1.svg);
}
.icon.h2 {
  background-image: url(/images/icons/type-h2.svg);
}
.icon.h3 {
  background-image: url(/images/icons/type-h3.svg);
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.editor h1 {
  font-weight: 900;
  font-size: 32px;
}

.editor h2 {
  font-weight: 600;
  font-size: 24px;
}

.editor h3 {
  font-weight: 400;
  font-size: 18px;
}

.icon.bullet-list,
.icon.bullet {
  background-image: url(/images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.number {
  background-image: url(/images/icons/list-ol.svg);
}

i.bold {
  background-image: url(/images/icons/type-bold.svg);
}

i.italic {
  background-image: url(/images/icons/type-italic.svg);
}

i.underline {
  background-image: url(/images/icons/type-underline.svg);
}
i.link {
  background-image: url(/images/icons/link.svg);
}

i.code {
  background-image: url(/images/icons/code.svg);
}

i.quote {
  background-image: url(/images/icons/chat-square-quote.svg);
}

i.code,
.icon.block-type.code {
  background-image: url(/images/icons/code.svg);
}

button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.PlaygroundEditorTheme__textUnderline {
  text-decoration: underline;
}

.PlaygroundEditorTheme__textItalic {
  font-style: italic;
}

.PlaygroundEditorTheme__textBold {
  font-weight: bold;
}

.PlaygroundEditorTheme__ul {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.editor ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.PlaygroundEditorTheme__ol1 {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

/* --- LINK EDITOR --- */
.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  transition: opacity 0.5s;
  will-change: transform;
}
/** Temporary */
.channels-popup {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  padding: 10px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 75px);
  box-sizing: border-box;
  margin: 12px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.link-editor div.link-cancel {
  background-image: url(/images/icons/close.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 28px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-confirm {
  background-image: url(/images/icons/success-alt.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.PlaygroundEditorTheme__link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.PlaygroundEditorTheme__link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.PlaygroundEditorTheme__ltr {
  text-align: left;
}

.link-editor .link-view {
  display: block;
  width: calc(100% - 24px);
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
}

.link-editor div.link-edit {
  background-image: url(/images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-trash {
  background-image: url(/images/icons/trash.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.PlaygroundEditorTheme__textCode {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.PlaygroundEditorTheme__code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
}

.PlaygroundEditorTheme__quote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.PlaygroundEditorTheme__code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.suggestions-floating {
  position: absolute;
  left: 0;
  top: 0;
}

.suggestions-floating-body {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}
.suggestion-item {
  cursor: pointer;
}
.suggestion-item:hover {
  background-color: gray;
  transition: all 0.3s ease-in;
}

.mention-user {
  @apply rounded-[2px] bg-primary-10 text-primary-pressed dark:text-primary-100-dark;
}

/* --- !Rich Editor --- */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/** --- masonry grid --- */
.masonry {
  /* Masonry container */
  column-count: 4;
  column-gap: 1em;
}

.item {
  /* Masonry bricks or child elements */
  background-color: #eee;
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

/** --- !masonry grid --- */
